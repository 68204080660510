@font-face {
  font-family: "Argo";
  src: url("../fonts/argo.otf") format("opentype");
}

@font-face {
  font-family: "Spartan";
  src: url("../fonts/spartan/Spartan-Thin.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Spartan";
  src: url("../fonts/spartan/Spartan-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Spartan";
  src: url("../fonts/spartan/Spartan-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Spartan";
  src: url("../fonts/spartan/Spartan-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Spartan";
  src: url("../fonts/spartan/Spartan-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Spartan";
  src: url("../fonts/spartan/Spartan-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Spartan";
  src: url("../fonts/spartan/Spartan-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Spartan";
  src: url("../fonts/spartan/Spartan-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Spartan";
  src: url("../fonts/spartan/Spartan-Black.ttf") format("truetype");
  font-weight: 900;
}
