@import "variables";

@mixin button-colors($color1, $color2) {
  background-color: $color1;
  border-color: $color2;
}

@mixin button-primary-colors {
  @include button-colors($main-color, $main-color);
}

@mixin button-primary-hover-colors {
  @include button-colors(transparent, $light-color);
}

@mixin top-margin() {
  margin-top: $base-margin;

  @media (min-width: $screen-md) {
    margin-top: $base-margin-md;
  }

  @media (min-width: $screen-xlg) {
    margin-top: $base-margin-lg;
  }
}

@mixin form-font-size() {
  font-size: 14px;
  line-height: 20px;

  @media (min-width: $screen-md) {
    font-size: 16px;
    line-height: 22px;
  }

  @media (min-width: $screen-xlg) {
    font-size: 20px;
    line-height: 28px;
  }
}
