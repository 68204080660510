@import "scss/variables";
@import "scss/reset";
@import "scss/mixins";
@import "scss/fonts";

$base-padding: 20px;
$base-padding-md: 25px;
$base-padding-lg: 40px;
$success-color: #91FF6B;

:root {
  --app-height: 100vh;
}

body {
  background: $background-color;
  padding: $base-padding;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;

  @media (min-width: $screen-md) {
    padding: $base-padding-md;
  }

  @media (min-width: $screen-xlg) {
    padding: $base-padding-lg;
  }
}

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(var(--app-height) - $base-padding * 2);
  color: $light-color;

  @media (min-width: $screen-md) {
    height: calc(var(--app-height) - $base-padding-md * 2);
  }

  @media (min-width: $screen-xlg) {
    height: calc(var(--app-height) - $base-padding-lg * 2);
  }
}

.videoBG {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 40px;

  .videoElement {
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  margin-top: auto;

  .logo {
    width: 264px;
    height: 180px;
    background: url("./outlaw-logo.png") no-repeat center;
    background-size: contain;

    @media (min-width: $screen-md) {
      width: 293px;
      height: 200px;
    }

    @media (min-width: $screen-xlg) {
      width: 520px;
      height: 354px;
    }
  }

  .description {
    @include top-margin;
    width: 320px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0;
    text-align: center;

    br {
      display: none;
    }

    @media (min-width: $screen-md) {
      width: 700px;
      font-size: 22px;
      line-height: 30px;

      br {
        display: block;
      }
    }

    @media (min-width: $screen-xlg) {
      font-size: 26px;
      line-height: 36px;
    }
  }

  .form {
    @include top-margin;
    @include form-font-size;
    display: flex;
    align-items: center;
    width: 320px;
    height: 49px;
    background-color: #292929;
    padding: 8px;
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif;
    letter-spacing: 0;
    text-align: center;
    border-radius: 8px;
    border: 1px solid $pure-white;

    @media (min-width: $screen-md) {
      width: 500px;
      height: 62px;
      padding: 12px;
    }

    @media (min-width: $screen-xlg) {
      width: 605px;
      height: 68px;
    }

    &.success {
      border-color: $success-color;

      .btn {
        background-color: $success-color;
      }
    }

    .email {
      width: 100%;
      color: $light-color;
      background-color: #292929;
      border: none;
      outline: none;
      margin-left: 8px;

      @media (min-width: $screen-md) {
        margin-left: 12px;
      }
    }

    .btn {
      margin-left: 12px;
      padding: 8px 12px;
      box-sizing: border-box;
      border-radius: 8px;
      font-weight: 700;
      background-color: #F2B204;
      color: #141313;
      border: none;
      white-space: nowrap;

      @media (min-width: $screen-md) {
        margin-left: 20px;
        padding: 8px 20px;
      }
    }
  }

  .success-block {
    @include form-font-size;
    display: flex;
    margin-top: 16px;
    color: $success-color;
    letter-spacing: 0;
    text-align: center;

    @media (min-width: $screen-md) {
      margin-top: 32px;
    }

    .icon-success {
      display: block;
      width: 19px;
      height: 19px;
      background: url("./icon-success.svg") no-repeat center;
      background-size: contain;
      margin-right: 16px;

      @media (min-width: $screen-md) {
        width: 28px;
        height: 28px;
      }
    }
  }
}

footer {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  width: 100%;
  margin-top: auto;
  margin-bottom: 24px;
  padding: 0 $base-top-margin;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0;

  @media (min-width: $screen-md) {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
  }

  @media (min-width: $screen-xlg) {
    margin-bottom: 32px;
    font-size: 18px;
    line-height: 25px;
  }

  a {
    color: $light-color;
  }
}
